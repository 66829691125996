import { get } from 'lodash';
import { TrackReference } from '@livekit/components-core';
import { Track } from 'livekit-client';

export const isVideoEnabled =(tracks: (TrackReference | undefined)[])=>  {
  if(tracks.length === 0 ) return false
  const videoTrack =  tracks?.find((track)=> track && track.source === Track.Source.Camera)
  if(videoTrack) {
    return !videoTrack.publication.isMuted
  }
  return false;

}

export const participantName = (tracks: (TrackReference | undefined)[])=> {
  if(tracks.length === 0 || ! tracks[0]) return ''
  const participant = tracks[0].participant
  return get(participant,'name') || get(participant,'participantInfo.identity') ||
  get(participant,'identity') }
