/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { VideoTrack, useTracks } from '@livekit/components-react';
import _ from "lodash";
import { LayoutProps } from '../../utils/common';
import {Track} from "livekit-client";
import VideoTileDetails from './VideoTileDetails';
import Avatar from '../../components/Avatar';
import { isVideoEnabled, participantName } from './utils';

const SpeakerLayout = ({  tracks: references, participantTracks,  sessionId }: LayoutProps) => {
  const candidate =  _.find(references,(item: any)=>_.get(item,'participant.metadata') === 'CANDIDATE')
  const mainTrack = _.isUndefined(candidate) ? _.get(references,'0'):candidate
  const remainingTracks = _.filter(references,(item)=> item?.participant?.identity !== mainTrack?.participant?.identity)
  const screenshareTracks = useTracks([Track.Source.ScreenShare], {
    onlySubscribed: true,
  });

  const isScreenShared = screenshareTracks?.length > 0
  const focusedTrack = isScreenShared ? screenshareTracks[0] : mainTrack;
  const sortedTracks = isScreenShared ? references.filter(item=>_.get(item,'publication.source') !== "screen_share") : remainingTracks

  const candidateJoined = isScreenShared || candidate

  const generateLayout=( isCandidateJoined : any )=>{
    if ( isCandidateJoined ){
        return (
          <div className="lk-focus-layout w-screen">
            <div className={'overflow-scroll w-[15.75rem] flex flex-col justify-center gap-1 bg-neutral-600'}>
              {_.map(_.groupBy(sortedTracks, 'participant.identity'),(trackRef) =>
                <div className={'relative h-[7.875rem] w-full rounded-md'}>
                  {isVideoEnabled(trackRef) ? <VideoTrack trackRef={trackRef.find(track => track && track.source === Track.Source.Camera)} className="rounded" /> :
                    <Avatar name={participantName(trackRef)} size='md' />}
                  <VideoTileDetails trackRef={trackRef[0]} isScreenShared={false} />
                </div>
              )}
            </div>
            <div className={'h-screen w-11/12 flex justify-center items-center relative bg-neutral-800'}>
              {focusedTrack && (isVideoEnabled([focusedTrack]) || focusedTrack.source === Track.Source.ScreenShare) ? <VideoTrack trackRef={focusedTrack} className="rounded" /> :
                <Avatar name={participantName([focusedTrack])} size={'xl'} />}
              <VideoTileDetails trackRef={focusedTrack} isScreenShared={isScreenShared} />
            </div>
          </div>
        )} else {
      return (
        <div className={'w-screen h-screen flex-wrap flex justify-center items-center bg-neutral-700 gap-1'}>
            {_.map(participantTracks,(trackRef) => (
            <div className={'w-[49.6%] h-1/2 relative bg-neutral-800 rounded'}>
              {isVideoEnabled(trackRef) ? <VideoTrack trackRef={trackRef.find(track => track.source === Track.Source.Camera)} className="rounded" /> :
                <Avatar name={participantName(trackRef)} size='xl' />}
                <VideoTileDetails trackRef={trackRef[0]} isScreenShared={false} />
            </div>
          ))}
        </div>
      )
    }
  }

  return (
    <div className={'relative'}>
      <div className={'py-1 px-3 rounded absolute top-2 left-2 bg-black bg-opacity-30 font-medium text-sm z-50'}>{sessionId}</div>
      {generateLayout(candidateJoined)}
    </div>
  )
};

export default SpeakerLayout;
