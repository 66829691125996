import React from 'react';
import classNames from 'classnames';
import {NetworkQualityLevelProps } from '../../utils/common';

// This component displays a visual indicator of network quality based on the `networkQualityLevel` prop.
// - 'poor': Red indicators
// - 'good': Yellow indicators
// - 'excellent': Green indicators

function NetworkQualityLevel(props:NetworkQualityLevelProps) {
  const { networkQualityLevel } = props;
  return (
    <div className="h-5 w-5 border border-transparent relative -mt-0.5">
      <div
        className={classNames(
          'absolute left-[0.34rem] right-0 bottom-0.5 h-[0.1875rem] w-[0.1875rem] rounded-lg',
          networkQualityLevel ==='poor' && 'bg-red-500',
          networkQualityLevel === "excellent" && 'bg-green-600',
          networkQualityLevel === "good" && 'bg-yellow-500',
          networkQualityLevel === "unknown" && 'bg-slate-400',
        )}
      ></div>
      <div
        className={classNames(
          'absolute h-3 w-3 rounded-lg border-2 border-transparent border-t-2 left-px top-2.5',
          networkQualityLevel ==='poor' && 'border-t-red-500',
          networkQualityLevel === "excellent" && 'border-t-green-600',
          networkQualityLevel === "good" && 'border-t-yellow-500',
          networkQualityLevel === "unknown" && 'border-t-slate-400',
        )}
      ></div>
      <div
        className={classNames(
          'absolute h-5 w-5 rounded-xl border-2 border-transparent border-t-2 -left-[0.1875rem] top-1.5',
          networkQualityLevel ==='poor' && 'border-t-slate-400',
          networkQualityLevel === "good" && 'border-t-yellow-500',
          networkQualityLevel === "excellent" && 'border-t-green-600',
          networkQualityLevel === "unknown" && 'border-t-slate-400',
        )}
      ></div>
      <div
        className={classNames(
          'absolute h-7 w-7 rounded-2xl border-2 border-transparent border-t-2 -left-[0.4375rem] top-0.5',
          networkQualityLevel === "excellent" ? "border-t-green-600" : "border-t-slate-400" ,
        )}
      ></div>
    </div>
  );
}

export default NetworkQualityLevel;
