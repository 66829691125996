import { join,map,get } from 'lodash';
import classNames from 'classnames';
import {AvatarProps} from '../../utils/common';

const sizes = {
  xxl: 'h-36 w-36 text-5xl font-medium',
  xl: 'h-24 w-24 text-3xl font-medium',
  lg: 'h-16 w-16 text-2xl font-medium',
  md: 'h-12 w-12 text-lg font-medium',
  sm: 'h-8 w-8 text-sm font-normal',
};

export const Avatar = (props:AvatarProps) => {
  const { name, size } = props; 
  const splitName = name?.split(/[-:_ ]/);
  const initials = join(
    map(splitName, (value, index) => (index < 2 ? value?.[0]?.toUpperCase() || '' : null)),
    '',
  );

  return (
  <div className='flex justify-center items-center bg-neutral-800 h-full w-full rounded'>
    <div className={classNames("leading-non text-neutral-900 font-semibold flex justify-center items-center rounded-full bg-neutral-100", get(sizes, size, sizes?.md))}>{initials}</div>
  </div>
  )
}

export default Avatar;