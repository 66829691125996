import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import classNames from 'classnames';
import { VideoTileDetailsProps } from '../../utils/common';
import NetworkQualityLevel from '../../components/NetworkQualityLevel';

const VideoTileDetails = (props: VideoTileDetailsProps) => {
  const { trackRef, isScreenShared } = props;

  const participant_type = _.get(trackRef, 'participant.participantInfo.metadata',"")
  const micMuted = _.get(_.find(_.get(trackRef, 'participant.participantInfo.tracks'), { mimeType: 'audio/red' }), 'muted')
  const micIcon = micMuted ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : <FontAwesomeIcon className={'h-3.5'} icon={faMicrophone} />
  const displayName =  _.get(trackRef, 'participant.participantInfo.name') || _.get(trackRef, 'participant.participantInfo.identity')
  const quality =_.get(trackRef, 'participant.connectionQuality',"poor")

  return (
    <div className='absolute bottom-1 left-1 bg-black bg-opacity-60 py-1 px-2 rounded-lg'>
      <div className='flex justify-center items-center'>
        <div className="max-w-[5rem] font-normal text-sm text-slate-50 truncate">{displayName }</div>
        <span className={classNames('text-slate-400 mx-2 h-5', isScreenShared && 'hidden')}>{micIcon}</span>
        <div className= {classNames(isScreenShared && 'hidden')}>
          <NetworkQualityLevel networkQualityLevel={quality} />
        </div>
      </div>
      <div className='text-xs'>{_.capitalize(participant_type)}</div>
    </div>
  );
}

export default VideoTileDetails;
