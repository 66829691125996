/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {  VideoTrack } from '@livekit/components-react';
import { Track } from 'livekit-client';
import { LayoutProps } from '../../utils/common';
import VideoTileDetails from './VideoTileDetails';
import { isVideoEnabled, participantName } from './utils';
import Avatar from '../../components/Avatar';

const SingleSpeakerLayout = ({ tracks: references, sessionId }: LayoutProps) => {
  return (
    <div className="lk-single-speaker-layout w-screen relative h-screen">
      <div className={'py-1 px-3 rounded absolute top-2 left-2 bg-black bg-opacity-30 font-medium text-sm z-50'}>{sessionId}</div>
      {isVideoEnabled(references) ? <VideoTrack trackRef={references.find(track => track && track.source === Track.Source.Camera)} /> :
        <Avatar name={participantName(references)} size={'xl'} />}
      <VideoTileDetails trackRef={references[0]} isScreenShared={false} />
    </div>
  );
};

export default SingleSpeakerLayout;
