/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useLayoutEffect } from 'react';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import EgressHelper from '@livekit/egress-sdk';
import Mixpanel from 'mixpanel-browser';
import './App.css';
import RoomPage from './Room';

function App() {
  const mixPanelEnabled =  process.env.REACT_APP_BUILD_ENV === 'prod'

  useLayoutEffect(() => {
      // @ts-ignore
    Mixpanel?.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        ignore_dnt: true,
        opt_out_tracking_by_default: mixPanelEnabled,
      });
      if (mixPanelEnabled) {
         Mixpanel?.opt_in_tracking();
      } else {
        Mixpanel?.opt_out_tracking();
      }
  }, [mixPanelEnabled]);

  return (
    <div className="container">
      <RoomPage
        // EgressHelper retrieves parameters passed to the page
        url={EgressHelper.getLiveKitURL()}
        token={EgressHelper.getAccessToken()}
        layout={EgressHelper.getLayout()}
      />
    </div>
  );
}

export default App;
