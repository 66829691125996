import * as sentry from '@sentry/react';
import { trim } from 'lodash';
import Mixpanel from 'mixpanel-browser';
import { eventLogActionProps, TrackEventParams } from './common';

export const trackEvent = ({ event, options }:TrackEventParams) => {
    try {
      const defaultOptions = {
        Application: 'Recorder-UI',
        'Application Environment': process.env.REACT_APP_BUILD_ENV || 'Local',
        ...options,
      };
      Mixpanel.track(event, { ...defaultOptions });
      // eslint-disable-next-line no-console
      console.log('Mixpanel Event:', event, { ...defaultOptions });
    } catch (e) {
      sentry.captureException(e);
    }
};

export const eventLogAction = ({ payload, event }:eventLogActionProps) => {
  const { sessionId }:any = payload || {};

  const commonProperties = {
    'Meeting ID': sessionId ? Number(trim(sessionId)) : null,
  };

  const events = (eventType:string) => {
    switch (eventType) {
      default:
        return { ...commonProperties };
    }
  };

  trackEvent({
    event,
    options: events(event),
  });
};
